<template>
<div>

  <v-card>
    <v-card-title>
      {{titulo}}
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="fas fa-search"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-container>
        <v-row>
          <v-col cols="12" sm="12" md="4">
            <v-autocomplete
              v-model="id_exporter"
              :items="customers"
              item-text="name"
              item-value="id"
              label="Escoge una Exportadora"
              @change="getByCustomer"
              clearable
              rounded
              solo
            ></v-autocomplete>
          </v-col>
        </v-row>
    </v-container>

      <!-- :items="currentItems" -->
    <v-data-table
      :headers="headers"
      :items="currentItems"
      :search="search"
      sort-by="id"
      sort-desc
      class="elevation-1"
      :loading="loading"
      loading-text="Consultando datos... Espere por favor"
    >
      <template v-slot:[`item.date_created`]="{ item }">
          <div>
            {{ dateFormat(item.date_created) }}
          </div>
      </template>
      <template v-slot:[`item.quantity`]="{ item }">
          <div>
            {{ parseFloat(item.quantity).toFixed(2) }}
          </div>
      </template>

      <template v-slot:top>
          <v-dialog v-model="dialog" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-form ref="form" v-model="valid">
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-autocomplete
                          v-model="editedItem.id_exporter"
                          :items="customers"
                          item-text="name"
                          item-value="id"
                          label="Escoge una Exportadora"
                          :rules="editedItem.id_exporterRules"
                          clearable
                          rounded
                          solo
                          return-object
                        ></v-autocomplete>
                      </v-col>

                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                            v-model="editedItem.constancy_number"
                            label="Número de Constancia"
                            type="number"
                            required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
                <v-btn color="blue darken-1" text @click="save">Guardar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="450">
            <v-card>
              <v-card-title>
                <span>Desea eliminar el comprobante de {{editedItem.exporter}}?</span>
                <v-spacer></v-spacer>
              </v-card-title>

              <v-card-actions>
                <v-btn color="green darken-1" text @click="close">Cancelar</v-btn>
                <v-btn color="green darken-1" text @click="eliminar">Aceptar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- COMPONENTS -->
          <v-dialog persistent v-model="dialogNote" max-width="1000">
            <v-card> 
              <v-spacer></v-spacer>
              <div>
                <v-btn
                  style="right:10px; top:10px;"
                  icon
                  color="error"
                  fab
                  absolute
                  @click="close"
                >
                  <v-icon dark>fa-times</v-icon> 
                </v-btn>
              </div>

              <DeleteConstancy :key="editedItem.id" :bill="editedItem.id" @closeModal="closeModal($event)"  v-if="dialogNote"></DeleteConstancy>
            </v-card>
          </v-dialog>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-icon color="primary" class="mr-2" @click="generatePdf(item)">fas fa-print</v-icon>
        <v-icon color="success" class="mr-2" @click="editItem(item)">fas fa-edit</v-icon>
        <v-icon color="red" @click="deleteItem(item)">fas fa-trash-alt</v-icon>
      </template>

      <template slot="body.append">

      <tr class="gray--text">
        <!-- <th class="title"></th> -->
        
        <th colspan="9" class="totales">
        <v-row class="pt-2">

          <v-col cols="12" sm="12" md="4">
            Total Oro: {{ numberWithCommas((parseFloat(sumField('quantity'))).toFixed(2)) }}
          </v-col>
        </v-row>
        </th>
        
      </tr>
      </template>

    </v-data-table>
  </v-card>
</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "axios";
import * as moment from 'moment';
import jsPDF from 'jspdf';
import DeleteConstancy from '@/components/DeleteConstancy.vue';

export default {
  components: { DeleteConstancy },
  name: "constance",
  data: () => ({
    dialog: false,
    dialogNote: false,
    samplings: [],
    currentItems: [],
    sumItems: [],
    items: [],
    customers: [],
    process: "",
    type: "",
    total_sum: "",
    id_customer: null,
    id_exporter: null,
    dates: [],
    modal: false,
    loader: null,
    loading3: false,
    dialogDelete: false,
    search: "",
    loading: false,
    titulo: "Constancia de Venta",
    headers: [
      { text: "Fecha", align: "left", value: "date_created"},
      { text: "Número de Constancia", align: "left", value: "constancy_number" },
      { text: "Exportadora", align: "left", value: "exporter" },
      // { text: "Número de Comprobante", value: "receipt" },
      { text: "Retención", value: "quantity" },
      { text: "Acciones", align: "center", value: "action", sortable: false }
    ],
    editedIndex: -1,

    valid: true,
    

    editedItem: {
      id_exporter: null,
      constancy_number: "",
      selected: [],
      id_exporterRules: [v => !!v || "Escoge una Exportadora"],
    },
    defaultItem: {
      id_exporter: null,
      constancy_number: "",
      selected: [],
      id_exporterRules: [v => !!v || "Escoge una Exportadora"],
    },
  }),

  computed: {
    
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Registro" : "Editar Registro";
    },
    ...mapState(["db","headers_db"]),
    dateRangeText: {
        get()
        {
            return this.dates.join(' ~ ')
        },
        set()
        {
            this.dates = []
        }
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    loader () {
        const l = this.loader
        this[l] = !this[l]

        setTimeout(() => (this[l] = false), 1000)

        this.loader = null
    },
  },

  methods: {
    ...mapActions(["validateSession"]),
    // Metodos base de datos
    async getSamplings(){
      await axios.get(this.db + 'constancias',
        {
          headers: this.headers_db
        }).then(response => {
          let datos2 = response.data.data;
          this.samplings = this.currentItems = datos2;
          // console.log(datos2);
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.validateSession(error).then((resp) => { if(resp) this.getSamplings(); })
        });
    },

    async getCustomers(){
      await axios.get(this.db + 'exporters',
        {
          headers: this.headers_db
        }).then(response => {
          this.customers = response.data.data;
          this.loading = false;
          // console.log(this.customers);
        })
        .catch((error) => {
          this.loading = false;
          this.validateSession(error).then((resp) => { if(resp) this.getCustomers(); })
        });
    },

    async getCompany(){
      await axios.get(this.db + 'company',
        {
          headers: this.headers_db
        }).then(response => {
          // console.log(response.data.data);
          this.company = response.data.data;
        })
        .catch((error) => {
          this.validateSession(error).then((resp) => { if(resp) this.getCompany(); })
        });
    },
    // FIN Metodos base de datos

    // Metodos Generales

    getByCustomer() {
      
      // console.log(this.id_exporter, this.samplings);
      if (this.id_exporter) {
        this.currentItems = this.samplings.filter(doc => {
          return doc.id_exporter == this.id_exporter;
        });
      }
      else {
        this.currentItems = this.samplings;
      }
    },

    closeModal(event){
      console.log("Cerrando modal");
      this.dialogNote = event;
      this.getSamplings();
    },
    
    dateFormat(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    
    sumField(key) {
      return this.currentItems.reduce((a, b) => parseFloat(a) + (parseFloat(b[key]) || 0), 0);
    },

    editItem(item) {
      this.editedIndex = this.samplings.indexOf(item);
      this.dialog = true;
      this.editedItem = Object.assign({}, item);
    },

    deleteItem(item) {
      console.log(item.id);
      this.editedItem = Object.assign({}, item);
      this.dialogNote = true;
    },

    showModal2() {
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.dialogDelete = false;
      this.dialogNote = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      }, 300);
    },

    save() {

      if (this.$refs.form.validate()) {
        axios.post(this.db + 'constancy/update', {
          id_exporter: this.editedItem.id_exporter.id ? this.editedItem.id_exporter.id : this.editedItem.id_exporter,
          constancy_number: this.editedItem.constancy_number,
          id_constancy: this.editedItem.id,
        },
        {
          headers: this.headers_db
        })
        .then((response) => {
          console.log(response.data.message);
          Object.assign(this.samplings[this.editedIndex], this.editedItem);
          this.generatePdf(this.editedItem);
          this.$store.dispatch("setSnackbar", {
            text: response.data.message,
          });
          this.getSamplings();
          this.close();
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("setSnackbar", {
            text: error,
          });
        });
      }
    },

    async generatePdf(item) {
        console.log('ITEM',item);
        let owner = this.company.name2;
        let clave_owner = this.company.clave_owner;
        let operation_key = this.company.operation_key;
        let exporter = item.id_exporter.name ? item.id_exporter.name : item.exporter;

        await axios.get(this.db + 'constancy/details/' + item.id,
        {
          headers: this.headers_db
        }).then(response => {
          // console.log(response.data.data);
          let selected = response.data.data;
          item.selected = [...selected].sort((a, b) => a.receipt - b.receipt);
        })
        
        let total_ret = parseFloat(item.total_ret).toFixed(2);

        const split_ret = total_ret.split(".");
        // const qq = n2wordsES(split_ret[0]).toUpperCase();
        
        moment.locale('es');
        // let day = moment().format("DD");
        // let month = moment().format("MMMM").toUpperCase();
        // let year = moment().format("YYYY");

        var doc = new jsPDF();
        // doc.setFont("helvetica", "bold");
        doc.setFontSize(12)

        let beginY = 21;
        
        // receipt,p.codihc,quantity,p.name
        doc.text(total_ret, 120, beginY);
        beginY += 6;
        doc.text(exporter, 80, beginY);
        beginY += 6;
        doc.text((item.selected.length).toString(), 50, beginY);
        beginY = 52;

        item.selected.forEach(element => {
          beginY += 6;
          const split = element.quantity.split(".");
          doc.text(element.receipt || "", 20, beginY);
          console.log(element);
          doc.text(element.codihc || "03-45893", 50, beginY);
          doc.text(split[0], 90, beginY);
          doc.text(split[1], 105, beginY);
          doc.text(element.name || "", 120, beginY);
        });
        beginY = 206;
        doc.text(split_ret[0], 90, beginY);
        doc.text(split_ret[1], 105, beginY);
        beginY += 15;
        // doc.text(day + " de " + month + " de " + year, 100, beginY);
        beginY += 15;
        doc.text(owner, 20, beginY);
        beginY += 15;
        doc.text(clave_owner, 20, beginY);
        beginY += 15;
        doc.text(operation_key, 20, beginY);
        
        // doc.text(year, 164, beginY, null, null, "center");

        // doc.autoPrint();
        const blob = doc.output("bloburl");
        window.open(blob);
    },

    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    eliminar() {
      this.deleteProcess(this.editedItem.id);
      this.close();
    },
    // async getAll() {
    //   this.loader = 'loading3'
    //   this.loading = true;
    //   await this.getSamplings();
    //   await this.getCustomers();
    // },
  },
  async mounted() {
    this.loading = true;
    await this.getSamplings();
    await this.getCustomers();
    this.getCompany();
  },
};
</script>

<style lang="scss">
  .totales {
    font-size: medium !important;
  }
</style>
