<template>
<div>

  <v-card elevation="0">
    <v-card-title>
      {{titulo}}
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="fas fa-search"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
    </v-card-title>

    <v-container>
          <v-row>

          <v-spacer></v-spacer>
          
          <v-col cols="12" sm="12" md="3">
            <v-btn
              color="red darken-2"
              class="ma-2 white--text"
              @click="dialogDelete = true"
              block
            >
              Eliminar <v-icon right >
                fas fa-trash
              </v-icon>
            </v-btn>
          </v-col>

          </v-row>
    </v-container>

    <v-data-table
      v-model="editedItem.selected"
      :headers="headers"
      :items="currentItems"
      :search="search"
      sort-by="nombre"
      class="elevation-1"
      :loading="loading"
      show-select
      checkbox-color="error"
      item-key="id"
      loading-text="Consultando datos... Espere por favor"
    >
    <template v-slot:[`item.name`]="{ item }">
      <div v-if="item.name">
        {{ item.name }}
      </div>
      <div v-else>
        {{ item.nombre }}
      </div>
    </template>
      <!-- <template v-slot:[`item.date_created`]="{ item }">
          {{ dateFormat(item.date_created) }}
      </template>
      <template v-slot:[`item.valor`]="{ item }">
        {{ numberWithCommas(item.valor) }}
      </template>
      
      <template slot="body.append">
      <tr class="gray--text">
        
        <th colspan="8" class="title">
        <v-row class="pt-2">
          <v-col cols="12" sm="12" md="6">
            Total: {{ numberWithCommas((parseFloat(sumField('cant'))).toFixed(2)) }}
          </v-col>
          <v-col cols="12" sm="12" md="6">
            Valor: {{ numberWithCommas((parseFloat(sumField('valor'))).toFixed(2)) }}
          </v-col>
        </v-row>
        </th>
        
      </tr>
    </template> -->

      <template v-slot:top>
        <!-- <v-toolbar flat color="white"> -->
          <!-- <v-spacer></v-spacer> -->

          <v-dialog v-model="dialogDelete" max-width="450">
            <v-card>
              <v-card-title>
                <span>Desea eliminar los comprobantes <br> seleccionados?</span>
                <v-spacer></v-spacer>
              </v-card-title>

              <v-card-actions>
                <v-btn color="grey" text @click="dialogDelete = false">Cancelar</v-btn>
                <v-btn color="red darken-1" text 
                :loading="loading1"
                :disabled="loading1" 
                @click="eliminar">Aceptar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

      </template>
      
    </v-data-table>
  </v-card>
</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "axios";
import * as moment from 'moment';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export default {
  name: "ground",
  data: () => ({
    dialog: false,
    dialogNote: false,
    currentItems: [],
    banks: [],
    places: [],
    id_place: null,
    dialogDelete: false,
    search: "",
    loading: "",
    loading1: false,
    loader: null,
    titulo: "COMPROBANTES DE RETENCIÓN",
    headers: [
      // { text: "Fecha", align: "left", value: "date_created"},
      { text: "Número de Comprobante", value: "receipt" },
      { text: "Clave del Productor", value: "codihc" },
      { text: "Nombre", align: "left", value: "name" },
      { text: "Retención", value: "quantity" },
      // { text: "Acciones", value: "action", align: "center", sortable: false }
    ],
    editedIndex: -1,

    currencyMask: createNumberMask({
                    prefix: '',
                    allowDecimal: true,
                    includeThousandsSeparator: true,
                    allowNegative: false,
                  }),

    valid: true,
    editedItem: {
      id: "",
      qq_ground: "",
      quantity: "",
      selected: [],
      qq_groundRules: [v => !!v || "Escriba una cantidad"],
      active: false
    },
    defaultItem: {
      qq_ground: "",
      quantity: "",
      selected: [],
      qq_groundRules: [v => !!v || "Escriba una cantidad"],
      active: false
    }
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Registro" : "Editar Registro";
    },
    ...mapState(["db","headers_db"]),
  },

  watch: {
    dialog(val) {
      val || this.close();
    },

    loader () {
        const l = this.loader
        this[l] = !this[l]

        setTimeout(() => (this[l] = false), 15000)

        this.loader = null
    },
  },

  methods: {
    ...mapActions(["validateSession"]),
    // Metodos base de datos
    async getBanks(){
      console.log("BILL", this.bill);
      await axios.get(this.db + 'constancy/details/' + this.bill,
        {
          headers: this.headers_db
        }).then(response => {
          this.currentItems = this.banks = response.data.data;
          console.log(this.currentItems);
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.validateSession(error).then((resp) => { if(resp) this.getBanks(); })
        });
    },

    eliminar(){
      let selected = this.editedItem.selected;
      if (selected.length > 0) {
        console.log("DELETE", selected);
        this.loader = 'loading1'
        
        axios.post(this.db + 'constancy/vouchers/delete', {
          bill: this.bill,
          selected: selected
        },
        {
          headers: this.headers_db
        })
        .then((response) => {
          console.log(response.data.message);
          this.$store.dispatch("setSnackbar", {
            text: response.data.message,
          });
          
          this.close();
        })
        .catch(function (error) {
          console.log(error);
          this.$store.dispatch("setSnackbar", {
            text: error,
          });
        });
      }
      else {
        this.$store.dispatch("setSnackbar", {
            text: "No hay notas de peso seleccionadas",
            color: 'warning'
          });
      }

    },
    // FIN Metodos base de datos


    // Metodos Generales

    // closeModal(event){
    //   console.log("Cerrando modal Pesos");
    //   this.dialogNote = event;
    //   this.getBanks();
    // },

    dateFormat(date) {
      return moment(date).format('DD/MM/YYYY');
    },

    sumField(key) {
      return this.currentItems.reduce((a, b) => parseFloat(a) + (parseFloat(b[key]) || 0), 0);
    },

    editItem(item) {
      this.editedIndex = this.banks.indexOf(item);
      this.dialog = true;
      this.editedItem = Object.assign({}, item);
    },

    deleteItem(item) {
      console.log(item.id);

      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    close() {
      console.log("CERRANDO");
      this.dialog = false;
      // this.dialogDelete = false;
      this.$emit('closeModal', false)
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.currencyMask = "";
        this.editedIndex = -1;
      }, 300);
    },

    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  async created() {
    this.getBanks();
  },
  props: {
      bill: String
  }
};
</script>

